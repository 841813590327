<script setup>
import Paragraph1 from "../text/Paragraph1.vue";
</script>

<template>
  <button class="flex items-center rounded-4xl border border-black p-3">
    <a
      v-for="(locale, index) in $i18n.availableLocales"
      :key="`locale-${locale}`"
      @click="changeLocale(locale)"
      :class="{ 'text-gray-300': $i18n.locale === locale }"
      class="cursor-pointer hover:text-gray-300"
    >
      <Paragraph1>
        {{ locale }}
      </Paragraph1>
      <span
        v-if="index !== $i18n.availableLocales.length - 1"
        class="mx-1 text-black"
        >/</span
      >
    </a>
  </button>
</template>

<script>
export default {
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>
