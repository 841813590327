<script setup>
import { ref } from "vue";
import ModalForm from "../components/forms/ModalForm.vue";
import FormWriteToUs from "../components/forms/FormWriteToUs.vue";
import BlockSectionTop from "../components/shared/BlockSectionTop.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import YellowBlackButton from "../components/buttons/Yellow-BlackButton.vue";
import YellowBorderWhiteButton from "../components/buttons/Yellow-BorderWhiteButton.vue";
import BlackBorderBlackButton from "../components/buttons/Black-BorderBlackButton.vue";
import Headline1 from "../components/text/Headline1.vue";
import Headline3 from "../components/text/Headline3.vue";
import Headline4 from "../components/text/Headline4.vue";
import Paragraph1 from "../components/text/Paragraph1.vue";
import Paragraph2_1 from "../components/text/Paragraph2_1.vue";
import Paragraph3 from "../components/text/Paragraph3.vue";

import SliderBlock from "../components/shared/SliderBlock.vue";
import { Splide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

const showModal = ref(false);
const showModalGetACosultation = ref(false);
</script>

<template>
  <!-- block - 1 - Hero -->
  <BlockSectionTop />
  <BlockSection>
    <Container>
      <div class="mx-2 flex flex-row justify-center">
        <div class="basis-full px-2 text-center lg:basis-8/12">
          <h1>
            <Headline1>
              {{ $t("header.services") }}
            </Headline1>
          </h1>
          <p class="my-8 md:my-12">
            <Headline4>
              {{ $t("services.block_1.top_text_1") }}
            </Headline4>
            <br />
            <Headline4>
              {{ $t("services.block_1.top_text_2") }}
            </Headline4>
          </p>
          <div class="mt-8">
            <YellowBlackButton @click="showModalGetACosultation = true">
              {{ $t("buttons.get_a_consultation") }}
            </YellowBlackButton>
          </div>
        </div>
      </div>
    </Container>
  </BlockSection>

  <!-- block - 2 - Slider -->
  <BlockSection class="px-4">
    <Container>
      <Splide :options="splideOptions" aria-label="My Favorite Images">
        <SliderBlock @open-modal="showModal = true" 
          v-for="(project, index) in portfolio_projects"
          :key="index"
          :title="project.title"
          :description="project.description"
          :li="project.li"
          :link="project.link"
          :NameButton="$t('buttons.apply_now')"
          :icon-src="project.iconSrc"
          :icon-alt="project.iconAlt"
          :orderBlock1="project.orderBlock1"
          :orderBlock2="project.orderBlock2"
          :orderBlockAll="project.orderBlockAll"
        />
      </Splide>
    </Container>
  </BlockSection>

  <!-- block - 3 - Brand strategy & Business Identity -->
  <BlockSection>
    <div class="rounded-t-[80px] bg-gray-300 py-24 md:rounded-t-[120px]">
      <Container>
        <div class="mx-2 flex flex-row flex-wrap items-center justify-around">
          <div class="mb-16 basis-full px-2 md:mb-0 md:basis-4/12">
            <h2>
              <Headline3>
                {{ $t("services.block_3.h2") }}
              </Headline3>
            </h2>
            <div class="my-8 md:my-12">
              <ul class="mb-6 list-inside list-disc md:mb-5 md:list-outside">
                <li>
                  <Paragraph2_1>
                    {{ $t("services.block_3.li_1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("services.block_3.li_2") }}
                  </Paragraph2_1>
                </li>
              </ul>
              <p>
                <Paragraph3>
                  {{ $t("services.block_3.p") }}
                </Paragraph3>
              </p>
            </div>
            <BlackBorderBlackButton @click="showModal = true" class="w-full">
              <button>
                {{ $t("buttons.apply_now") }}
              </button>
            </BlackBorderBlackButton>
          </div>
          <div class="basis-full px-2 md:basis-5/12">
            <img
              src="/img/services/brand_strategy.webp"
              :alt="$t('services.block_3.h2')"
              class="h-auto w-full"
            />
          </div>
        </div>
      </Container>
    </div>

    <!-- block - 4 - Content strategy -->
    <div class="bg-black py-20">
      <Container>
        <div class="mx-2 flex flex-row flex-wrap justify-between">
          <div class="mb-10 basis-full px-2 lg:basis-3/12">
            <div class="text-white">
              <h2>
                <Headline3>
                  {{ $t("services.block_4.h2") }}
                </Headline3>
              </h2>
              <p class="my-6">
                <Paragraph2_1>
                  {{ $t("services.block_4.p") }}
                </Paragraph2_1>
              </p>
            </div>
            <YellowBorderWhiteButton @click="showModal = true">
              <button>
                {{ $t("buttons.apply_now") }}
              </button>
            </YellowBorderWhiteButton>
          </div>
          <div class="basis-full text-white lg:basis-8/12">
            <div class="flex flex-row flex-wrap justify-between">
              <div class="mb-10 basis-full px-2 md:basis-1/2">
                <h3 class="mb-6">
                  <Paragraph1>
                    {{ $t("services.block_4.h3_1") }}
                  </Paragraph1>
                </h3>
                <ul class="list-inside list-disc">
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_1_1") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_1_2") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_1_3") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_1_4") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_1_5") }}
                    </Paragraph2_1>
                  </li>
                </ul>
              </div>
              <div class="mb-10 basis-full px-2 md:basis-1/2">
                <h3 class="mb-6">
                  <Paragraph1>
                    {{ $t("services.block_4.h3_2") }}
                  </Paragraph1>
                </h3>
                <ul class="list-inside list-disc">
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_2_1") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_2_2") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_2_3") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_2_4") }}
                    </Paragraph2_1>
                  </li>
                  <li>
                    <Paragraph2_1>
                      {{ $t("services.block_4.li_2_5") }}
                    </Paragraph2_1>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="basis-full px-2">
            <img
              src="/img/services/content_strategy.webp"
              :alt="$t('services.block_4.h2')"
              class="h-auto w-full rounded-4xl"
            />
          </div>
        </div>
      </Container>
    </div>
  </BlockSection>

  <!-- block - 5 - Business process automation -->
  <BlockSection>
    <Container>
      <div
        class="mx-4 flex flex-row flex-wrap items-center rounded-4xl bg-gray-300"
      >
        <div
          class="flex-grow basis-full rounded-t-4xl bg-black md:basis-1/2 md:rounded-l-4xl md:rounded-tr-none"
        >
          <div class="flex flex-row justify-center">
            <div class="basis-9/12 text-white lg:basis-8/12">
              <div class="py-20 2xl:my-40">
                <Headline3>
                  {{ BusinessProcessAutomation.title }}
                </Headline3>
                <ul class="my-12 list-outside list-disc">
                  <li
                    v-for="(item, index) in BusinessProcessAutomation.li"
                    :key="index"
                  >
                    <Paragraph2_1>
                      {{ item }}
                    </Paragraph2_1>
                  </li>
                </ul>
                <YellowBorderWhiteButton @click="showModal = true">
                  <button>
                    {{ $t("buttons.apply_now") }}
                  </button>
                </YellowBorderWhiteButton>
              </div>
            </div>
          </div>
        </div>
        <div
          class="basis-full overflow-hidden rounded-b-4xl bg-gray-300 md:basis-1/2"
        >
          <img
            src="/img/services/business_automation.webp"
            class="h-auto w-auto sm:h-[550px] md:h-[350px] lg:h-[400px] xl:h-[500px] 2xl:h-[650px]"
            :alt="BusinessProcessAutomation.title"
          />
        </div>
      </div>
    </Container>
  </BlockSection>

  <ModalForm
    v-if="showModalGetACosultation"
    @close="showModalGetACosultation = false"
  >
    <h3>
      <Paragraph1>
        {{ $t("buttons.get_a_consultation") }}
      </Paragraph1>
    </h3>
  </ModalForm>

  <ModalForm v-if="showModal" @close="showModal = false">
    <h3>
      <Paragraph1>
        {{ $t("buttons.apply_now") }}
      </Paragraph1>
    </h3>
  </ModalForm>
</template>

<script>
export default {
  components: {
    ModalForm,
    FormWriteToUs,
    BlockSection,
    Container,
    YellowBlackButton,
    YellowBorderWhiteButton,
    BlackBorderBlackButton,
    Headline1,
    Headline3,
    Headline4,
    Paragraph1,
    Paragraph2_1,
    Paragraph3,
    SliderBlock,
  },
  data() {
    return {
      portfolio_projects: [
        {
          title: this.$t("services.block_2.1.title"),
          li: [
            this.$t("services.block_2.1.li_1"),
            this.$t("services.block_2.1.li_2"),
            this.$t("services.block_2.1.li_3"),
            this.$t("services.block_2.1.li_4"),
            this.$t("services.block_2.1.li_5"),
          ],
          link: "ApplyNow",
          iconSrc: "/img/services/web_development.webp",
          iconAlt: this.$t("services.block_2.1.title"),
          orderBlock1:
            "lg:order-2 justify-end order-2 rounded-tr-none rounded-br-4xl rounded-bl-4xl lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
          orderBlock2:
            "lg:order-1 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-bl-4xl lg:rounded-tr-none",
          orderBlockAll: "items-end justify-end mb-6 md:mb-8 lg:mb-0",
        },
        {
          title: this.$t("services.block_2.2.title"),
          li: [
            this.$t("services.block_2.2.li_1"),
            this.$t("services.block_2.2.li_2"),
            this.$t("services.block_2.2.li_3"),
            this.$t("services.block_2.2.li_4"),
            this.$t("services.block_2.2.li_5"),
          ],
          link: "ApplyNow",
          iconSrc: "/img/services/web_design.webp",
          iconAlt: this.$t("services.block_2.2.title"),
          orderBlock1:
            "lg:order-1 order-2 rounded-tr-none justify-end rounded-br-4xl lg:rounded-br-none rounded-bl-4xl lg:rounded-bl-4xl lg:rounded-tr-none lg:rounded-tl-4xl",
          orderBlock2:
            "lg:order-2 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-tl-none lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
          orderBlockAll: "items-end justify-end",
        },
      ],

      BusinessProcessAutomation: {
        title: this.$t("services.block_5.h2"),
        li: [
          this.$t("services.block_5.li_1"),
          this.$t("services.block_5.li_2"),
          this.$t("services.block_5.li_3"),
          this.$t("services.block_5.li_4"),
        ],
      },

      splideOptions: {
        paginationDirection: "rtl",
        gap: 20,
        rewind: true,
        destroy: window.innerWidth < 1024,
      },
    };
  },
};
</script>
