export default {
  header: {
    home: "Главная",
    about_us: "О нас",
    services: "Услуги",
    portfolio: "Портфолио",
    pack_my_project: "Упакуйте мой проект!",
    inquire: "Заявка",
  },
  footer: {
    contact: "Контакты",
    glossary: "Глоссарий",
    more_about_services: "Больше об услугах",
    terms_conditions: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    llc: "© 2024 Vera Realty LCC",
  },
  buttons: {
    get_to_know_the_team: "Команда",
    view_the_project: "Посмотреть проект",
    view_all: "Посмотреть все",
    apply_now: "Обратиться",
    get_a_consultation: "Консультация",
    go_home: "Главная",
    view_the_website: "Посмотреть сайт",
  },
  forms: {
    name: "Имя*",
    email: "Email*",
    submit: "Отправить",
    select: {
      select: "Выбрать услугу*",
      web_design: "Web дизайн",
      web_development: "Web разработка",
      content_strategy: "Контент стратегия",
      business_process: "Автоматизация процессов",
      brand_strategy: "Бренд стратегия & бизнес айдентика",
      all: "Выбрать все",
    },
  },
  cookie: {
    title: "Уведомление.",
    description:
      "TermsFeed использует файлы cookie для обеспечения необходимой функциональности сайта",
  },
  home: {
    styling_your_business: "Стиль вашего бизнеса",
    fill_in_for: "Заполните форму, если вы заинтересованы в услугах из списка",
    abouts: {
      1: {
        title: "Мы",
        description: "команда креативных диджитал-гуру",
      },
      2: {
        title: "Наше",
        description: "видение основано на богатом международном опыте",
      },
      3: {
        title: "Мы",
        description:
          "впечатляем профессионализмом,подтвержденным нашим портфолио",
      },
      4: {
        title: "Мы",
        description: "следуем актуальным трендам",
      },
      5: {
        title: "Мы",
        description: "стилизуем бизнес с фидбэком Wow.",
      },
      6: {
        title: "Мы",
        description:
          "создаем не просто сайты, а технологичные продающие ресурсы",
      },
    },
    services: {
      1: {
        iconAlt: "Web разработка",
        title: "Web разработка",
        description: "Разработка сайтов и других веб-ресурсов",
      },
      2: {
        iconAlt: "Web дизайн",
        title: "Web дизайн",
        description: "Дизайн сайтов и других веб-ресурсов",
      },
      3: {
        iconAlt: "Бренд стратегия & бизнес айдентика",
        title: "Бренд стратегия & бизнес айдентика ",
        description: "Создаем уникальный стиль вашего бизнеса",
      },
      4: {
        iconAlt: "Контент стратегия",
        title: "Контент стратегия ",
        description: "Все о контенте вашего бизнеса",
      },
      5: {
        iconAlt: "Автоматизация бизнес-процессов",
        title: " Автоматизация бизнес-процессов",
        description: "Оцифровка бизнеса в CRM для роста эффективности",
      },
    },
    portfolio_projects: {
      1: {
        title: "Vera Fund",
        description:
          'Проект с особенной целевой аудиторией. В наших руках он визуально обновился и приобрел больше "воздуха". Мы убрали массивные яркие детали из брендбука и превратили их в аккуратные акценты. Посмотрите обновленный проект.',
        iconAlt: "Vera Fund Project",
      },
      2: {
        title: "Coral Ridge Townhomes",
        description:
          "Один из наших первых проектов от логотипа до продаж - комплекс из 8 таунхаусов в уникальном районе в Форт-Лодердейле, Флорида. В этом проекте важной задачей было подчеркнуть особенности Coral Ridge.  Мы перенесли яркие ассоциации в проект, начиная с брендинга и заканчивая рекламными материалами.",
        iconAlt: "Проект Coral Ridge Townhomes",
      },
      3: {
        title: "Aventura Quattro",
        description:
          "Наш комплексный проект, целью которого было создание эффективных ресурсов и инструментов, полноценно представляющих данный арендный комплекс. Когда мы начинали проект, у него было только название. Посмотрите, что он представляет собой сейчас.",
        iconAlt: "Проект Aventura Quattro",
      },
    },
  },
  about_us: {
    h1: "Команда",
    boss: {
      name: "Ник Полюшкин",
      job: "Основатель",
      text: "Наш вдохновляющий лидер с впечатляющим бэкграундом в маркетинге и в одном из самых сложных бизнесов - в недвижимости. Высокий уровень ответственности и способность видеть больше определяют подход Ника и нашей команды к бизнесу и клиентам",
    },
    evgenia: {
      name: "Женя Желтухина",
      job: "Маркетинг директор",
    },
    sergey: {
      name: "Сережа Спирин",
      job: "IT директор",
    },
    michael: {
      name: "Миша Полупанов",
      job: "HR директор",
    },
    olya: {
      name: "Оля Иванова",
      job: "UX/UI дизайнер",
    },
    alexandra: {
      name: "Саша Подугольникова",
      job: "SM менеджер",
    },
    vitaly: {
      name: "Виталик Бобров",
      job: "Youtube стратег",
    },
  },
  services: {
    block_1: {
      top_text:
        "Как клиенты видят ваш бизнес? Знают ли вообще клиенты о вашей компании? Знаете ли вы как сделать ваш сайт и соц сети продающим? Знаете ли вы, что делать, если ваши ресурсы не эффективны? Мы знаем и можем помочь.",
    },
    block_2: {
      1: {
        title: "Web разработка",
        li_1: "Определение технологий и инструментов",
        li_2: "План разработки с этапами и сроками выполнения",
        li_3: "Верстка и фронтенд-разработка",
        li_4: "Бэкенд-разработка",
        li_5: "Тестирование и настройка систем сайта",
        li_5: "Размещение сайта на сервере",
      },
      2: {
        title: "Web дизайн",
        li_1: "Разработка дизайна сайта",
        li_2: "Разработка дизайна лендинга",
        li_3: "Дизайн email серий",
        li_4: "Визуалы для социальных сетей",
        li_5: "Web бизнес айдентика",
      },
    },
    block_3: {
      h2: "Бренд стратегия & Бизнес айдентика",
      li_1: "Брендбук (лого, шрифты, цвета, гайд и т.д.)",
      li_2: "Бизнес айдентика (папки, визитки, бланки, брошюры, мерчи и т.д.)",
      p: "Все макеты/материалы готовы к печати",
    },
    block_4: {
      h2: "Контент стратегия",
      p: "Разработка контент планов, контент гайдов, сценариев и технических заданий",
      h3_1: "Развитие в социальных сетях",
      li_1_1: "Аудит текущих профилей в соц сетях",
      li_1_2: "Подготовка контент стратегии",
      li_1_3: "Реализация контент стратегии",
      li_1_4:
        "Запуск прдвижения через различные каналы привлечения трафика в соц сетях",
      li_1_5: "Анализ эффективности через метрики",

      h3_2: "Стратегия Youtube канала",
      li_2_1: "Анализ вашего YouTube канала",
      li_2_2: "Управление контентом в YouTube",
      li_2_3: "Продвижение YouTube канала",
      li_2_4: "Монетизация YouTube",
      li_2_5: "Оптимизация вашей YouTube стратегии",
    },
  },
  portfolio: {
    h1: "Портфолио",
    website: "сайт",
    project: "проект",
    yellow_block: "Здесь может быть ваш проект",
    vera_projects: {
      h2: "Полная упаковка проекта",
      project_1: {
        title: "Vera Fund",
      },
      project_2: {
        title: "Aventura Quattro",
      },
      project_3: {
        title: "Vera Realty",
      },
      project_4: {
        title: "Coral Ridge Townhomes",
      },
      project_5: {
        title: "Vera Office Tower",
      },
      project_6: {
        title: "Coliving",
      },
      project_7: {
        title: "AAM Project",
      },
      project_8: {
        title: "Vera Manage",
      },
      project_9: {
        title: "Vera Finder",
      },
      project_10: {
        title: "Aventura Apartments",
      },
      project_11: {
        title: "CE Pony",
      },
    },
    projects: {
      h2: "Сайты, созданные на основе готового стиля",
      project_1: {
        title: "The Elser",
      },
      project_2: {
        title: "Baccarat",
      },
      project_3: {
        title: "Polyus Consulting",
      },
      project_4: {
        title: "One Park Tower",
      },
      project_5: {
        title: "St Regis",
      },
      project_6: {
        title: "Valusso Referral",
      },
      project_7: {
        title: "Waldorf Astoria",
      },
      project_8: {
        title: "Casa Bella",
      },
    },
    // ------
    // ------
    view: "Открыть",
    back: "< назад",
    next_case: "следующий кейс >",
    menu_items: {
      about_the_project: {
        title: "О проекте",
        vera_fund: {
          headline: "Vera Fund",
          content:
            'A project with a target audience that is special in every sense. In our hands, it was visually updated and gained more "air". We removed massive bright details from the brandbook and turned them into neat accents. Discover new materials and resources for the project.',
        },
        coral_ridge: {
          headline: "Coral Ridge Townhomes",
          content:
            "Один из наших первых проектов от логотипа до продаж - комплекс из 8 таунхаусов в уникальном районе в Форт-Лодердейле, Флорида. В этом проекте важной задачей было подчеркнуть особенности Coral Ridge.  Мы перенесли яркие ассоциации в проект, начиная с брендинга и заканчивая рекламными материалами.",
        },
        aventura_quattro: {
          headline: "Aventura Quattro",
          content:
            "Aventura Quattro - наш комплексный проект, целью которого было создание эффективных ресурсов и инструментов, полноценно представляющих данный арендный комплекс. Когда мы начинали проект, у него было только название. Посмотрите, что он представляет собой сейчас.",
          image: "/img/portfolio_aventura_quattro/path_hover.svg",
          hover_img: "hover:scale-x-125 transition ease-out delay-0",
        },
      },
      business_identity: {
        title: "Бизнес айдентика",
        vera_fund: {
          content:
            "Разработан уникальный стиль, основанный на современном, легко читаемом шрифте, палитре, предполагающей акцентирование с помощью цвета, и графических элементах, подчеркивающих легкость и простоту логотипа.",
        },
        coral_ridge: {
          content:
            "Проект, пронизанный особенностями уникального района Корал Ридж, Форт-Лодердейл, Флорида. Близость к океану и коралловому рифу отражена в каждом элементе стратегии бренда проекта. Разработана четкая идентичность, напоминающая эту локацию. ",
        },
        aventura_quattro: {
          content:
            "Айдентика для комплекса арендных апартаментов. Само здание строится в современном бутиковом стиле, что нашло отражение в визуальных элементах, от логотипа до цветовой гаммы проекта.",
        },
      },
      web_design_development: {
        title: "Web дизайн/разработка",
        vera_fund: {
          content:
            "Разработан трендовый дизайн для многостраничного сайта с материалами для email рассылки, веб-материалы для размещения рекламы и т. д.).",
        },
        coral_ridge: {
          content:
            "Разработан трендовый дизайн для многостраничного сайта с материалами для email рассылки, веб-материалы для размещения рекламы и т. д.).",
        },
        aventura_quattro: {
          content:
            "Разработан трендовый дизайн для многостраничного сайта с материалами для email рассылки, веб-материалы для размещения рекламы и т. д.).",
        },
      },
      crm_integration: {
        title: "CRM интеграция",
        vera_fund: {
          content:
            "Комплексное управление проектами включает в себя совместную работу всех функций, приложения CRM, анализ больших данных, права доступа, расширенную отчетность и диаграммы Ганта, управление рабочей нагрузкой сотрудников, автоматизированные процессы и шаблоны, а также мобильное приложение.",
        },
        coral_ridge: {
          content:
            "Комплексное управление проектами включает в себя совместную работу всех функций, приложения CRM, анализ больших данных, права доступа, расширенную отчетность и диаграммы Ганта, управление рабочей нагрузкой сотрудников, автоматизированные процессы и шаблоны, а также мобильное приложение.",
        },
        aventura_quattro: {
          content:
            "Комплексное управление проектами включает в себя совместную работу всех функций, приложения CRM, анализ больших данных, права доступа, расширенную отчетность и диаграммы Ганта, управление рабочей нагрузкой сотрудников, автоматизированные процессы и шаблоны, а также мобильное приложение.",
        },
      },
      social_media_content: {
        title: "Контент для соц сетей",
        vera_fund: {
          content:
            "Обновление проекта в социальных сетях. Изменение визуальных решений, органических и рекламных активностей с целью повышения вовлеченности существующей аудитории и привлечения новых потенциальных клиентов.",
        },
        coral_ridge: {
          content:
            "Перед нами стояла задача создать соответствующую презентацию проекта 8 элитных таунхаусов в социальных сетях, чтобы привлечь первые лиды уже на этапе предпроектной подготовки и понять интерес аудитории к проекту",
        },
        aventura_quattro: {
          content:
            "Профиль в социальных сетях был создан на основе утвержденной бренд-стратегии на этапе подготовки объекта к строительству. Контент-план профиля был разработан исходя из целей проекта, включая задачи ознакомления аудитории с особенностями объекта и его локацией. ",
        },
      },
      youtube_develpment: {
        title: "Youtube стратегия",
        vera_fund: {
          content:
            "Для начала работы по продвижению на YouTube был проведен аудит канала и определены ключевые возможности для роста. С учетом особенностей аудитории проекта было налажено сотрудничество с партнерами, проведены целевые рекламные кампании и выстроены эффективные стратегии монетизации.",
        },
        coral_ridge: {
          content:
            'YouTube-кампания для проекта "Таунхаусы" была запущена с канала группы компаний, дополнена дополнительными настройками. Дальнейшая база для продвижения на YouTube была подготовлена для этапа начала строительства проекта.',
        },
        aventura_quattro: {
          content:
            "Для усиления комплекса продвижения проекта было утверждено создание видеоконтента и проведение рекламных кампаний на YouTube. Видеоролики о проекте размещаются на каналах группы компаний, что укрепляет репутацию проекта и лояльное отношение потенциальных клиентов",
        },
      },
    },
  },
  more_about_services: {
    block_1: {
      h2: "Автоматизация бизнес процессов",
      p: "Настройка CRM-системы для оцифровки результатов и повышения эффективности бизнеса",
      h3_1: "Создание логической схемы операционных бизнес-процессов для продаж (продвижение сделки):",
      li_1_1:
        "Создание схемы технического задания в MIRO с подробной логикой CRM-воронки Битрикс24 Приглашение сотрудников в Битрикс24 по email",
      li_1_2:
        "Отправка приглашений сотрудникам по email, как это предусмотрено клиентом",
      li_1_3: "Создание организационной структуры в Битрикс24",
      li_1_4: "Распределение сотрудников в соответствии с иерархией компании",
      li_1_5:
        "Оптимизация карточек лида, сделки, контакта и компании, создание до 60 полей",
      li_1_6:
        "Создание необходимых полей для карточек новых и повторных клиентов, сделок, контактов и компаний",
      li_1_7:
        "Создание полей типа: строка, список, дата/время, адрес карты, ссылка, файл, деньги, да/нет, число",
      li_1_8: "Создание поля резервирования ресурсов",
      li_1_9: "Создание полей, связанных с каталогами и элементами CRM",
      li_1_10:
        "Создание дополнительных разделов в карточках лидов, сделок, контактов и компаний",
      li_1_11: "Импорт клиентской базы данных",
      li_1_12:
        "Импорт существующих баз данных контактов и компаний в Битрикс24",
      li_1_13: "Настройка CRM-каталогов Битрикс24",
      li_1_14: "Создание источников информации о лидах, сделках и контактах",
      li_1_15: "Настройка типов контактов",
      li_1_16: "Настройка типов компаний",
      li_1_17: "Настройка типов сделок",
      h3_2: "Настройка этапов (воронок) и статусов (фаз) лидов и сделок",
      li_2_1:
        "Настройка статусов для воронки лидов в соответствии с логической схемой процесса продаж",
      li_2_2: 'Настройка статусов для воронки "Сделки по продаже"',
      li_2_3:
        'Создание автоматической воронки в Leads (для автоматического "проталкивания")',
      li_2_4: "Создание автоматической воронки для неудачных сделок",
      li_2_5: "Создание автоматической воронки для холодных звонков",
      li_2_6: "Создание автоматической воронки для обратной связи",
      li_2_7: "Настройка прав доступа к CRM Битрикс24",
      li_2_8: "Настройка ролей (до 12 ролей)",
      li_2_9: "Назначение ролей сотрудникам и отделам",
      li_2_10: "Интеграция Bitrix24 с Email",
      li_2_11: "Интеграция 6 Email аккаунтов с Bitrix24",
      li_2_12: "Распределение прав доступа между учетными записями",
      li_2_13: "Интеграция Bitrix24 с телефонией",
      li_2_14:
        'Подключение до 6 номеров мобильных телефонов с помощью услуги "Мои звонки".',
      li_2_15: "Подключение IP телефонии от Bitrix24, Mango, UIS, Megafon",
      li_2_16: "Интеграция Bitrix24 с сайтом",
      li_2_17:
        "Подключение форм сайта, созданных на Tilda и других конструкторах, с готовым решением для интеграции с Битрикс24",
      li_2_18: "Создание до 12 форм CRM для встраивания кода в другие CMS",
      li_2_19:
        "Настройка формы обратного звонка, онлайн-чата и формы обратной связи для виджета Битрикс24 на сайте одной компании",
      li_2_20: "Интеграция Битрикс24 с мессенджерами и социальными сетями",
      li_2_21: "Интеграция с VK и Одноклоассники",
      li_2_22: "Интеграция с WhatsApp, используя Wazzup или ChatApp сервис",
      li_2_23: "Интеграция с Telegram, используя бота",
      li_2_24: "Интеграция с Telegram, используя Wazzup или ChatApp сервис",
      li_2_25: "Интегация с Avito",
      li_2_26: "Назначение ответственных лиц по каждому каналу связи",
      li_2_27: "Автоматизация Bitrix24",
      li_2_28:
        "Автоматическое назначение ответственных за лид, сделку и контакт",
      li_2_29:
        "Автоматическое планирование следующего звонка, встречи, задачи и напоминание менеджеру (настройка роботов в лидах и сделках, до 40 роботов)",
      li_2_30:
        "Контроль сроков обработки клиента. Робот будет отправлять уведомления менеджеру и руководителю о просроченных лидах и сделках",
      li_2_31:
        "Роботы будут автоматически отправлять сообщения контактам с нужными статусами в воронке продаж",
      li_2_32:
        "Роботы будут автоматически отправлять сообщения лидам из автоматической воронки лидов",
      li_2_33:
        "Роботы будут автоматически отправлять сообщения контактам из автоматической воронки при неудачных сделках",
      li_2_34:
        "Роботы будут автоматически отправлять сообщения контактам из автоматической воронки для холодных звонков",
      li_2_35:
        "Роботы будут автоматически отправлять сообщения контактам из автоматической воронки для получения обратной связи",
      li_2_36:
        "Настройка автоматического создания документов (коммерческое предложение, счет-фактура, договор, расписка)",
      li_2_37:
        "Настройка автоматического изменения данных для сделки, контакта, компании",
      li_2_38: 'Настройка раздела "Задачи и проекты"',
      li_2_39: "Создание шаблонов для повторяющихся задач (до 10 шаблонов)",

      h3_3: " Настройка внешнего вида",
      li_3_1: "Установка логотипа компании",
      li_3_2: "Установка фона для всех сотрудников",
      li_3_3: "Настройка карточек элементов для всех сотрудников",
      li_3_4: "Настройка CRM маркетинга",
      li_3_5: "Подключение автоматических email-рассылок по этапам воронки",
      li_3_6:
        "Подключение автоматической рассылки сообщений в мессенджерах и социальных сетях с этапов воронки",
      li_3_7:
        'Подключение "Рекламные источники клиентов" для отслеживания источников клиентов.',
      li_3_8: "Настройка создания шаблона документа",
      li_3_9: "Создание коммерческого предложения, договора, счета, акта",
      li_3_10: "Обучение работы с системой",
      li_3_11: "Загрузка базы знаний на портал Битрикс24",
      li_3_12:
        "Запись видеоролика о работе с настроенной системой с последующей загрузкой его в базу знаний на портале Битрикс24",
    },
    block_2: {
      h2: "Контент стратегия",
      p: "Разработка контент-планов, руководств по контенту, сценариев и технических заданий.",

      1: {
        h3: "Стратегия канала Youtube",

        h4_1: "Анализ и планирование",
        li_1: {
          1: "Оцените текущее состояние канала: проанализируйте аудиторию, контент, статистику просмотров и вовлеченность.",
          2: "Определение целевой аудитории и ее потребностей",
          3: "Изучение конкурентов и тенденции развития отрасли",
          4: "Постановка SMART-цели и KPI для отслеживания прогресса",
        },

        h4_2: "Создание контента",
        li_2: {
          1: "Разработка контент-план, включающего регулярное производство видеороликов, соответствующих интересам и тенденциям аудитории",
          2: "Создание высококачественных видеоматериалов, обеспечивая хорошее освещение, звук и монтаж",
          3: "Использование убедительных заголовков, описания и тегов для улучшения SEO на YouTube",
        },

        h4_3: "Продвижение канала",
        li_3: {
          1: "Использование социальных сетей и других платформ для привлечения аудитории",
          2: "Коллаборации с другими блогерами и брендами, чтобы расширить охват аудитории",
          3: "Запуск рекламных кампаний на YouTube и других платформах для привлечения новых подписчиков",
        },

        h4_4: "Монетизация",
        li_4: {
          1: "Настройка монетизации через партнерскую программу YouTube",
          2: "Разработка и продажа собственных продуктов или услуг",
          3: "Коллаборации с брендами для спонсорства и рекламы",
        },

        h4_5: "Аналитика и оптимизация",
        li_5: {
          1: "Регулярный анализ данных YouTube Analytics для оценки эффективности контента и стратегии.",
          2: "Внесение корректив в контент, планирование и методы продвижения на основе аналитических данных",
          3: "Постоянная адаптация к изменениям в алгоритмах YouTube и предпочтениях аудитории.",
        },
        p: "Эта стратегия должна быть гибкой и адаптируемой к изменениям в алгоритмах YouTube, тенденциям контента и потребностям аудитории. Сосредоточьтесь на создании ценного и высококачественного контента, чтобы повысить лояльность и вовлеченность аудитории",
      },
      2: {
        h3: "Развитие социальных сетей",

        h4_1: "Подготовительные этапы",
        li_1: {
          1: "Брифинг: Заполните бриф, чтобы понять масштаб и цели проекта.",
          2: "Анализ целевой аудитории (TA): Проанализируйте целевую аудиторию, чтобы понять ее предпочтения, поведение и потребности",
          3: "Конкурентный анализ: Изучите конкурентов, чтобы определить их сильные и слабые стороны, а также стратегии.",
          4: "Разработка индивидуальной стратегии продвижения: на ближайший месяц или полугодие с учетом результатов анализа",
        },

        h4_2: "Работа над контентом",
        li_2: {
          1: "Составление индивидуального контент-плана: Разработка контент-плана на месяц вперед для всех согласованных каналов с учетом аудитории и стратегических целей",
          2: "Подготовка необходимых материалов:",
          li: {
            1: "Проведение съемок в соответствии с техническим заданием(ТЗ)",
            2: "Запрос статичных и видеоматериалов",
          },
          3: "Размещение в соответствии с контент-планом: Выполнение контент-плана с запланированными публикациями.",
          4: "Сбор и анализ статистики: Сбор и анализ данных по итогам выступлений для оценки вовлеченности, охвата и общей эффективности.",
        },

        h4_3: "Работа с трафиком",
        li_3: {
          1: "Разработка стратегии органического продвижения: Создание стратегии органического продвижения через REELS с упором на интересный контент, использующий специфические возможности платформы",
          2: "Запуск рекламных кампаний: Используйте Facebook Ads Manager для запуска целевых рекламных кампаний, направленных на увеличение охвата, вовлеченности и конверсии.",
        },
      },
    },
    block_3: {
      h2: "Дизайн",
      p: " Conceptualizing the look and feel of your project",

      1: {
        h3: "Web дизайн",
        li: {
          1: "Разработкеа дизайна сайта",
          2: "Разработка дизайна лендинга",
          3: "Разработка дизайна email серий",
          4: "Визуальные материалы для постов в социальных сетях, историй, рекламных баннеров различных форматов и т. д.",
          5: "Веб-имидж (брошюры, веб-визитки, фирменные бланки и т.д.)",
        },
      },

      2: {
        h3: "Бренд стратегия & Бизнес айдентика",
        li: {
          1: "Брендбук (лого, шрифты, цвета, гайд)",
          2: "Бизнес айдентика папки, визитки, бланки, брошюры и т.д.). Все материалы готовы к печати",
        },
      },
    },
  },

  term: {
    h1: "Правила и условия",
    h2_1: "Акцепт:",
    p_1: "Для применения этих условий не обязательно, чтобы клиент подписал согласие с ними. Если клиент принимает расценки/оценку, считается, что он убедился в том, что условия применимы, и принял эти положения и условия в полном объеме. Пожалуйста, внимательно ознакомьтесь с этими положениями и условиями. Любая покупка или использование наших услуг подразумевает, что вы прочитали и приняли наши положения и условия.",
    h2_2: "Тарифы",
    p_2_1:
      "Стоимость услуг, предоставляемых Polyus Studio, определяется в коммерческом предложении, которое Клиент получает по электронной почте. Расценки действительны в течение 30 дней (одного месяца). Polyus Studio оставляет за собой право изменить или отказаться от предоставления предложения по истечении 30 дней (одного месяца).",
    p_2_2:
      "Если с клиентом не согласовано иное, все услуги по проектированию и разработке приложений/веб-дизайну требуют предоплаты в размере не менее пятидесяти процентов (50%) от общей суммы предложения проекта до предоставления работы клиенту для ознакомления. Второй взнос в размере двадцати пяти процентов (25 %) взимается на этапе разработки, а оставшиеся двадцать пять процентов (25 %) от общей суммы проектного предложения - по завершении работы, до загрузки на сервер или выпуска материалов. Оплата услуг производится кредитным/дебетовым, чековым, банковским или электронным переводом.",
    h2_3: "Обзор клиента",
    p_3: "Polyus Studio предоставит Клиенту возможность ознакомиться с внешним видом и содержанием веб-сайта на этапе проектирования и после завершения разработки веб-сайта в целом. По завершении проекта такие материалы будут считаться принятыми и утвержденными, если Клиент не уведомит Polyus Studio об обратном в течение десяти (10) дней с момента предоставления материалов Клиенту.",
    h2_4: "Сроки выполнения и контроль содержания",
    p_4_1:
      "Polyus Studio установит и публично разместит или предоставит веб-сайт Клиента к дате, указанной в предложении/котировке/оценке проекта, или к дате, согласованной с Клиентом после получения Polyus Studio первоначальной оплаты, если только отсрочка не будет специально запрошена Клиентом и согласована Polyus Studio.",
    p_4_2:
      "В свою очередь, клиент соглашается делегировать отдельное лицо в качестве основного контактного лица для оказания помощи Polyus Studio в продвижении заказа удовлетворительным и оперативным образом.",
    p_4_3:
      "В ходе проекта Polyus Studio потребует от Клиента предоставить контент веб-сайта: текст, изображения, видео/фильмы и аудиофайлы, номера телефонов, адреса и т.д.",
    p_4_4:
      "Непредоставление требуемого содержания <br /> Polyus Studio - это небольшой бизнес. Чтобы оставаться эффективными, мы должны обеспечить выполнение запланированных работ в установленное время. В некоторых случаях нам приходится отклонять предложения по другим проектам и запросам, чтобы обеспечить выполнение запланированных работ в назначенное время.",
    p_4_5:
      "Именно поэтому мы просим вас (клиента) заранее предоставить всю необходимую информацию. В случае, если работа над вашим сайтом не может быть завершена из-за того, что мы не предоставили требуемую информацию в оговоренные сроки, и в результате мы задерживаемся, мы оставляем за собой право взимать дополнительную плату в размере до 25 %. Если ваш проект включает в себя поисковую оптимизацию (SEO), нам необходимо заранее предоставить активы/контент, включающий в себя текст, мультимедийные элементы (такие как видео, аудио файлы), статьи, записи в блогах, описания продуктов и любую другую информацию, представленную конечным пользователям вашего приложения/веб-сайта, чтобы SEO можно было эффективно спланировать и завершить.",
    p_4_6:
      "Если вы согласитесь предоставить нам требуемую информацию и впоследствии не сделаете этого в течение 7 дней (одной недели) после начала проекта, мы оставляем за собой право закрыть проект, а оставшийся баланс подлежит немедленной оплате. Проще говоря, все вышеупомянутое условие гласит: не давайте нам добро на начало работ, пока вы не будете к ним готовы.",
    h2_5: "Оплата",
    p_5_1:
      "Счета будут предоставлены Polyus Studio по завершении проекта и должны быть оплачены до того, как приложение/веб-сайт будет опубликован, запущен или размещен на сервере. Счета высылаются только по электронной почте. Счета должны быть оплачены после их получения или в иной срок, указанный нашим отделом счетов. За неоплаченные счета в течение 30 (тридцати) дней после даты выставления счета взимается плата за обслуживание в размере более одного процента (1%) в месяц.",
    p_5_2:
      '"В случае, если Polyus Studio будет вынуждена прибегнуть к услугам коллекторского агентства или юридической фирмы для взыскания просроченной задолженности перед Polyus Studio, вы (клиент) соглашаетесь оплатить все расходы коллекторского агентства, судебные издержки, гонорары адвокатов или сопутствующие расходы, связанные с взысканием задолженности."',
    h2_6: "Дополнительные расходы",
    p_6: "Клиент соглашается возместить Polyus Studio любые дополнительные расходы, необходимые для завершения работы. Примерами могут быть покупка специальных шрифтов, стоковых фотографий, видео, доменных имен, стоимость хостингового сервера, плата за написание контента и т. д.",
    h2_7: "Веб-браузеры",
    p_7_1:
      "Polyus Studio стремится обеспечить совместимость веб-сайтов с большинством браузеров посетителей. Веб-сайты разработаны для работы с наиболее популярными современными браузерами (например, Firefox, Internet Explorer, Google Chrome, Safari и т. д.). Клиент признает, что Polyus Studio не может гарантировать корректную работу всех браузеров в различных операционных системах.",
    p_7_2:
      "Polyus Studio не несет ответственности за веб-страницы, которые не отображаются приемлемым образом в новых версиях браузеров, выпущенных после разработки и передачи веб-сайта Клиенту. Поэтому Polyus Studio оставляет за собой право назначить цену за любую работу, связанную с изменением дизайна сайта или кода сайта для обеспечения совместимости с обновленным программным обеспечением браузеров.",
    h2_8: "Дефолт",
    p_8: "Счета, не оплаченные в течение 30 (тридцати) дней после даты выставления счета, будут считаться неоплаченными. Если Клиент не выполняет обязательства по оплате и сохраняет какую-либо информацию или файлы на веб-серверах Polyus Studio или третьих лиц, Polyus Studio может по своему усмотрению удалить все такие файлы со всех соответствующих серверов. Polyus Studio не несет ответственности за любую потерю файлов/данных, понесенную в результате удаления сервиса. Удаление таких файлов не освобождает Клиента от обязанности оплатить все неоплаченные расходы, начисленные на счет Клиента. Чеки, возвращенные по причине недостаточности средств, взимают плату за возврат в размере $25.00 USD, а счет Клиента немедленно считается неисполненным.",
    h2_9: "Расторжение договора",
    p_9: "Прекращение предоставления услуг Клиентом должно быть оформлено в виде письменного уведомления и вступает в силу с момента получения такого уведомления. Запросы о прекращении оказания услуг, отправленные по электронной почте или телефону, не будут выполняться до тех пор, пока не будут подтверждены в письменном виде. Клиенту будет выставлен счет за дизайн и разработку, выполненные до даты первого уведомления об отмене, с полной оплатой в течение тридцати (30) дней.",
    h2_10: "Возмещение ущерба",
    p_10: "Все услуги Polyus Studio могут быть использованы только в законных целях. Пользуясь нашими услугами, вы соглашаетесь возместить ущерб и оградить Polyus Studio от любых претензий, возникших в результате использования вами наших услуг, которые наносят ущерб вам или любой другой стороне.",
    h2_11: "Авторское право",
    p_11: "Клиент сохраняет авторские права на данные, текст, файлы, графику, видео, доменные имена и логотипы, предоставленные Клиентом. Предоставляя такие материалы, Клиент предоставляет Polyus Studio права на публикацию и использование таких материалов. Клиент должен получить разрешение и права на использование любой информации или файлов, авторские права на которые принадлежат третьим лицам. Клиент также несет ответственность за предоставление Polyus Studio разрешения и прав на использование таких материалов. Договор на проектирование и разработку и/или размещение сайта рассматривается как гарантия Клиента для Polyus Studio, что все такие разрешения и права были получены. Подтверждение наличия таких разрешений и полномочий может быть запрошено.",
    h2_12: "Стандартная доставка медиафайлов",
    p_12: "Если в предложении по проекту не указано иное, настоящее Соглашение предполагает, что любой текст, фотографии и другая графика (в формате .gif, .jpeg, .png или .tiff) будут предоставлены Клиентом в электронном формате (текстовые файлы ASCII, переданные по электронной почте, FTP или через наш сторонний веб-инструмент управления проектами Basecamp). Несмотря на то, что Polyus Studio предпримет все разумные попытки вернуть Клиенту любые изображения или печатные материалы, предоставленные для использования при создании веб-сайта Клиента, такой возврат не может быть гарантирован.",
    h2_13: "Кредит на дизайн",
    p_13: "Ссылка на Polyus Studio будет размещена либо мелким шрифтом, либо небольшой графикой в нижней части сайта заказчика. Если используется графика, она будет оформлена таким образом, чтобы вписаться в общий дизайн сайта. Если клиент потребует удалить кредит на дизайн, Polyus Studio удалит его в разумные сроки, не более 7 рабочих дней. Клиент также соглашается с тем, что сайт, разработанный и созданный для клиента, может быть представлен в портфолио Polyus Studio.",
    h2_14: "Требования к доступу",
    p_14: "Если приложение/веб-сайт Клиента будет установлено на стороннем сервере, Polyus Studio должен быть предоставлен временный доступ на чтение/запись к серверу Клиента и каталогам баз данных, доступ к которым должен осуществляться по FTP. В зависимости от специфики проекта может потребоваться настройка других ресурсов на сервере.",
    h2_15: "Изменения после подачи заявки/запуска веб-сайта",
    p_15: "Polyus Studio не несет ответственности за любые изменения, внесенные третьими лицами на страницы клиента после установки на сервер и запуска сайта. Такие изменения включают, но не ограничиваются, редактированием, добавлением, модификацией или удалением.",
    h2_16: "Активы, приобретенные от имени клиентов",
    p_16: "Polyus Studio может приобретать активы от имени клиента для его приложений/сайтов, но оплата и продление срока действия этих активов является обязанностью клиента. Потеря, аннулирование или иная потеря актива, вызванная неоплатой или несвоевременной оплатой, не является ответственностью Polyus Studio. Клиент должен вести учет сроков оплаты, чтобы обеспечить своевременное поступление платежей.",
    h2_17: "Общие сведения",
    p_17: "Polyus Studio не несет ответственности за любые изменения, внесенные третьими лицами в приложения/веб-сайты Клиента после установки на сервер и запуска приложения/веб-сайта в работу. Такие изменения включают, но не ограничиваются, редактированием, добавлением, модификацией или удалением.",
    h2_18: "Регулирующее законодательство",
    p_18: "Настоящее Соглашение регулируется законодательством Соединенных Штатов Америки.",
    h2_19: "Ответственность",
    p_19: "Polyus Studio настоящим снимает с себя, своих дочерних предприятий, компаний, сотрудников и/или агентов всю ответственность, возникающую в связи с любыми неточностями, упущениями, задержками, ошибками, потерей или повреждением работ/фотографий клиентов, предоставленных для сайта. Вся ответственность Polyus Studio перед Клиентом в отношении любых претензий или нарушений настоящего Соглашения, независимо от того, возникли они по халатности или нет, ограничивается суммой, уплаченной за Услуги по настоящему Соглашению, в связи с которыми возникло нарушение.",
    h2_20: "Делимость",
    p_20_1:
      "В случае если одно или несколько положений настоящего Соглашения будут признаны недействительными, незаконными или не имеющими исковой силы, остальные положения настоящего Соглашения не будут нарушены, и Соглашение не будет считаться недействительным только по этой причине. Такое недействительное, незаконное или не имеющее исковой силы положение должно быть заменено взаимоприемлемым действительным, законным и имеющим исковую силу положением, которое наиболее полно соответствует намерениям сторон, лежащим в основе недействительного положения.",
    p_20_2:
      "Настоящие Положения и условия заменяют собой все предыдущие заявления, договоренности или соглашения. Подпись клиента или внесение аванса означает согласие и принятие настоящих Положений и условий. Оплата онлайн является принятием наших условий и положений.",
  },
  privacy: {
    h1: "Политика конфиденциальности",
    block_1: {
      h2: "Мы можем собирать и обрабатывать следующую информацию о вас:",
      li_1: "информацию, которую вы предоставляете, заполняя формы на нашем веб-сайте (www.polyusstudio.com); и информацию, предоставленную в печатном виде или любым другим способом: ваше имя, дату рождения, адрес, интересы, маркетинговые предпочтения; при необходимости вашу кредитную информацию; и любую другую личную информацию, имеющую отношение к предоставляемым нами услугам. Эта информация может быть предоставлена, когда вы регистрируете интерес, заключаете сделки с нами, подписываетесь на наши услуги, получаете информационные бюллетени или запрашиваете информацию;",
      li_2: "переписка или другие контакты, которые вы можете иметь с нами;",
      li_3: "сведения о сделках, которые вы совершаете через наш сайт, и любые сообщения по телефону, лично или любым другим способом, связанные с этими сделками. Это может включать запись некоторых телефонных разговоров, например, связанных с живыми торгами;",
      li_4: "сведения о ваших посещениях нашего сайта, включая, помимо прочего, данные о трафике, журналы веб-передач или другие данные о связи и ресурсах, к которым вы обращаетесь.",
      p: "Предоставленная вами информация будет использоваться для обработки транзакций, но также может быть использована для внутренней обработки данных, например, для составления статистической информации о ваших интересах и использовании сайта www.polyusstudio.com. В качестве услуги для вас мы также можем использовать эту информацию для персонализации контента, предлагаемого на www.polyusstudio.com.",
    },
    block_2: {
      h2: "Специальная информация, которую мы можем собирать через www.polyusstudio.com",
      p: "Мы можем собирать и обрабатывать следующую информацию, полученную в результате использования вами сайта www.polyusstudio.com.",
    },
    block_3: {
      h2: "Файлы Cookie",
      p_1: "Cookie - это простой текстовый файл, который хранится на вашем компьютере или мобильном устройстве на сервере веб-сайта, и только этот сервер может получить или прочитать содержимое этого cookie. Каждый файл cookie уникален для вашего веб-браузера. Он содержит некоторую анонимную информацию, такую как уникальный идентификатор, название сайта и несколько цифр и чисел. Они позволяют веб-сайту запоминать такие вещи, как ваши предпочтения или то, что находится в вашей корзине. При использовании нашего сайта мы размещаем на вашем компьютере файлы cookie трех категорий, перечисленных ниже.",
      p_2: "Строго необходимые: Эти файлы cookie необходимы для того, чтобы вы могли перемещаться по сайту и использовать его функции, такие как доступ к защищенным разделам сайта; многие функции нашего сайта не будут работать должным образом или вообще не будут работать, если вы не разрешите использовать эти файлы cookie. Поэтому мы можем оказаться не в состоянии предоставить многие из услуг, которые вы можете запросить, если вы не согласитесь на использование этих файлов cookie.",
      p_3: "Производительность: Эти файлы cookie собирают информацию о том, как посетители используют веб-сайт, например, на какие страницы посетители заходят чаще всего. Эти файлы cookie не собирают информацию, идентифицирующую посетителя: вся информация, которую собирают эти файлы cookie, является агрегированной и, следовательно, анонимной. Эта информация используется только для улучшения работы веб-сайта.",
      p_4: "Функциональность: Эти файлы cookie позволяют веб-сайту запоминать сделанный вами выбор (например, имя пользователя, язык или регион, в котором вы находитесь) и предоставлять расширенные, более персональные функции (например, запоминать изменения, внесенные вами в размер текста и шрифты).",
      p_5: "Если вы проживаете за пределами ЕС, мы также используем четвертый тип файлов cookie для таргетинга или рекламы:",
      p_6: "Таргетинг или реклама: Иногда мы сотрудничаем с третьими сторонами для предоставления более релевантных рекламных объявлений и сбора веб-статистики. Эти третьи лица могут использовать файлы cookie, веб-маяки и другие технологии отслеживания для мониторинга ваших посещений как сайта Vera Realty, так и других сайтов. Они отслеживают ваше поведение в Интернете и определяют использование веб-сайта, помогая рекламодателям показывать вам рекламу и контент, которые более релевантны для вас. Некоторые примеры того, что делают таргетинговые и рекламные файлы cookie:",
      p_7: "Предоставляют рекламодателям информацию о вашем посещении наших сайтов, чтобы они могли показывать вам рекламу, которая соответствует вашему запросу.",
      p_8: "Ограничение количества показов рекламы.",
      p_9: "Помогают определить эффективность рекламной кампании.",
      p_10: "Не беспокойтесь - ни один из этих файлов cookie не хранит персональную информацию, и мы используем только проверенных рекламных партнеров. Чтобы вы знали, у этих третьих сторон есть своя политика конфиденциальности, и она может отличаться от нашей.",
      p_11: "IP-адрес <br /> Мы можем собирать информацию о вашем компьютере, включая ваш интернет-адрес, операционную систему и тип браузера, когда вы посещаете наш сайт. Ваш интернет-адрес используется для идентификации и сбора статистической или демографической информации о наших клиентах в целом. Мы также используем эту информацию для внутреннего администрирования системы, чтобы помочь диагностировать проблемы с нашими серверами, управлять нашим сайтом и обслуживанием клиентов или пользователей.",
    },
    block_4: {
      h2: "Как используется эта личная информация?",
      p_1: "Polyus Studio собирает, записывает, хранит и использует ваши персональные данные по всему миру среди компаний Vera Group для четко определенных целей, включая, но не ограничиваясь следующим:",
      li_1: "предоставление вам информации, продуктов или услуг, которые вы запрашиваете у нас или которые, по нашему мнению, заинтересуют вас в целевом и актуальном виде. Это может включать, в частности, аукционы, частные продажи, услуги по хранению и транспортировке, оценку, недвижимость, образование и страхование;",
      li_2: "выполнение наших обязательств, вытекающих из любых договоров, заключенных между вами и нами;",
      li_3: "уведомление вас об изменениях в наших продуктах и услугах;",
      li_4: "обеспечение того, чтобы наш веб-сайт был представлен наиболее эффективным образом для вас и вашего компьютера.",
      li_5: "(если вы не возражаете) информирование вас о предстоящих распродажах, мероприятиях и услугах.",
      p_2: "Мы можем связаться с вами в вышеуказанных целях посредством телефонных звонков, электронной почты, текстовых или SMS-сообщений и других средств.",
    },
    block_5: {
      h2: "Обмен информацией",
      p_1: "Мы не будем передавать ваши персональные данные никому за пределами компании Polyus Studio, кроме как в соответствии с законом и как указано в настоящей Политике. В каждой из стран, в которых мы работаем, действуют свои законы о конфиденциальности. Наша Политика конфиденциальности будет отражать их.",
      p_2: "Однако время от времени нам может потребоваться раскрывать ваши личные данные определенным третьим лицам.",
      p_3: "Агенты или подрядчики Polyus Studio <br /> Нам может потребоваться раскрыть определенную личную информацию нашим агентам или подрядчикам, чтобы Polyus Studio могла обрабатывать транзакции или общаться с вами. Если мы делаем это, то на основании того, что эти агенты/подрядчики обязаны сохранять конфиденциальность и безопасность информации и не будут использовать ее ни для каких других целей, кроме как для выполнения услуг, которые они оказывают для Polyus Studio.",
      p_4: "Борьба с мошенничеством и регулирующие органы <br /> Нам может потребоваться хранить и раскрывать определенную информацию о вас регулирующим органам и соответствующим агентствам для проведения проверок по борьбе с отмыванием денег и торговыми санкциями, а также для помощи в предотвращении мошенничества, в частности, в соответствии с нашими обязательствами по борьбе с отмыванием денег и в связи с торговыми санкциями. Мы будем раскрывать эту информацию в соответствии с требованиями закона.",
      p_5: "Кредитные организации <br /> Нам может потребоваться раскрыть определенную личную информацию о вас кредитным агентствам для проведения соответствующих проверок, когда вы заключаете с нами сделки.",
      p_6: "Раскрытие информации по закону <br /> Хотя мы не будем добровольно раскрывать ваши персональные данные, мы можем раскрыть такую информацию, если мы обязаны это сделать в соответствии с действующим постановлением суда, если мы получили законный запрос от государственных или правоохранительных органов, если мы обязаны это сделать в соответствии с другими юридическими процессами, или если это необходимо для защиты прав или собственности Polyus Studio.",
      p_7: "Передача и обработка данных по всему миру <br /> Являясь международной организацией, Polyus Studio может обрабатывать вашу личную информацию, как описано выше, в странах за пределами ЕЭЗ. В каждой из этих стран действуют различные законы о конфиденциальности, обеспечивающие разные уровни защиты вашей личной информации. Наша Политика конфиденциальности отражает эти различия, и мы будем действовать в соответствии с этой Политикой во всех юрисдикциях, в которых мы работаем. Предоставляя свои персональные данные Polyus Studio, вы соглашаетесь с тем, что мы будем обрабатывать ваши персональные данные в любой юрисдикции в соответствии с настоящей Политикой.",
      p_8: "Обращение к вам <br /> Для того чтобы предоставить вам маркетинговую информацию о наших продуктах и услугах, мы можем связаться с вами по электронной почте, текстовым сообщениям, SMS или любым другим службам обмена сообщениями, по телефону, факсу и по почте. В нижней части любого маркетингового электронного письма будет четко обозначена опция отказа от подписки на будущие рассылки. Кроме того, вы можете в любое время отправить электронное письмо на адрес info@polyusstudio.com с просьбой исключить ваше имя из наших списков.",
      p_9: "Ваши права <br /> Вы имеете законное право на получение копии информации, которую мы храним о вас, включая информацию о том, как она будет использоваться, и на обеспечение точности этой информации. Вы также имеете право попросить нас не обрабатывать ваши личные данные в маркетинговых целях - вы можете воспользоваться этим правом, отметив определенные поля в формах, которые мы используем для сбора данных, или в любое время, связавшись с нами по адресу info@polyusstudio.com.",
    },
    block_6: {
      h2: "Безопасность",
      p_1: "Мы предпримем все разумные и необходимые меры для защиты безопасности и целостности всей личной информации, предоставленной на нашем сайте или любым другим способом в электронном или ином виде.",
      p_2: "Мы обеспечим надлежащую защиту ваших персональных данных в Polyus Studio от несанкционированного физического и электронного доступа. Эти меры включают в себя электронный брандмауэр и различные другие меры защиты, которые включают в себя проверку на вирусы, установку патчей безопасности, тестирование уязвимостей, планирование резервного копирования и восстановления, обучение сотрудников, аудит безопасности и другие шаги, направленные на постоянное улучшение защиты данных. Тем не менее, мы не можем гарантировать, что информация во время передачи через Интернет или любую компьютерную сеть будет полностью защищена от несанкционированного вторжения, доступа или манипуляций. Мы не несем ответственности за раскрытие информации в результате ошибок или несанкционированных действий третьих лиц. В любом случае, если мы считаем, что личные данные были скомпрометированы, мы уведомим вас об этом.",
      p_3: "Сайты третьих лиц <br /> Сайты Polyus Studio содержат ссылки на другие сайты, которые не управляются Polyus Studio. Информация, которую вы предоставляете нам, не будет передаваться на другие сайты. Однако эти сайты могут собирать личную информацию о вас в соответствии с их собственной политикой конфиденциальности. Polyus Studio не несет ответственности за правила конфиденциальности или содержание таких сайтов.",
      p_4: "Как долго Polyus Studio будет хранить мою личную информацию? <br /> Мы будем хранить вашу информацию в наших системах до тех пор, пока это необходимо для соответствующей деятельности, или до тех пор, пока это оговорено в любом соглашении между вами и нами.",
      p_5: "Изменения в данной политике <br /> Мы оставляем за собой право в любое время вносить изменения и дополнения в настоящую Политику конфиденциальности и любые наши методы обеспечения конфиденциальности. Мы уведомим пользователей этого сайта, разместив любое обновление политики на этой странице, и такие изменения вступят в силу немедленно и без дополнительного уведомления. При необходимости мы можем напрямую уведомить вас об изменениях в Политике конфиденциальности либо по электронной почте, либо с помощью заметного уведомления на нашем сайте.",
      p_6: "Как связаться с компанией по вопросам о персональных данных? <br /> Если у вас возникли какие-либо вопросы в связи с использованием и защитой ваших персональных данных, пожалуйста, свяжитесь с нами по адресу info@polyusstudio.com.",
    },
  },
};
