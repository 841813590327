<script setup>
import Headline1 from "../components/text/Headline1.vue";
import Headline3 from "../components/text/Headline3.vue";
import Paragraph2 from "../components/text/Paragraph2.vue";
import BlockSectionTop from "../components/shared/BlockSectionTop.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import AboutUsCard from "../components/page/about/AboutUsCard.vue";
</script>

<template>
  <BlockSection>
    <BlockSectionTop />

    <!-- block - 1 - Headline 1 -->
    <Container>
      <div class="mx-2 flex flex-row">
        <div class="mb-14 basis-full px-2 text-center md:mb-20 lg:text-left">
          <h1>
            <Headline1>
              {{ $t("about_us.h1") }}
            </Headline1>
          </h1>
        </div>
      </div>
    </Container>

    <!-- block - 2 - Team -->
    <Container>
      <div class="mx-2 flex flex-row flex-wrap">
        <div class="mb-20 flex basis-full flex-row flex-wrap">
          <div class="mb-14 basis-full px-2 text-start lg:mb-0 lg:basis-4/12">
            <div class="overflow-hidden rounded-4xl">
              <img
                src="/img/about_us/nick.webp"
                class="h-auto w-full duration-500 hover:translate-y-3 hover:scale-110"
                :alt="$t('about_us.boss.name') + $t('about_us.boss.job')"
              />
            </div>
          </div>
          <div class="flex flex-wrap text-start lg:basis-8/12">
            <div class="flex basis-full flex-row flex-wrap">
              <div class="basis-full px-2 sm:basis-1/2">
                <h2>
                  <Headline3>
                    {{ $t("about_us.boss.name") }}
                  </Headline3>
                </h2>
              </div>
              <div class="basis-full px-2 sm:basis-1/2 sm:text-end">
                <Paragraph2>
                  {{ $t("about_us.boss.job") }}
                </Paragraph2>
              </div>
            </div>
            <div class="basis-full px-2">
              <hr class="mb-4" />
              <p>
                <Paragraph2>
                  {{ $t("about_us.boss.text") }}
                </Paragraph2>
              </p>
            </div>
          </div>
        </div>
        <div class="flex basis-full flex-row flex-wrap">
          <AboutUsCard
            v-for="(employee, index) in Team"
            :key="index"
            :name="employee.name"
            :image="employee.image"
            :job="employee.job"
            :class="employee.class"
            class="h-auto w-full"
          />
        </div>
      </div>
    </Container>
  </BlockSection>
</template>

<script>
export default {
  components: {
    Headline1,
    Headline3,
    Paragraph2,
    BlockSection,
    Container,
    AboutUsCard,
  },
  computed: {
    Team() {
      return [
        {
          name: this.$t("about_us.evgenia.name"),
          job: this.$t("about_us.evgenia.job"),
          image: "/img/about_us/zhenya.webp",
          class: "md:mb-20 mb-14",
        },
        {
          name: this.$t("about_us.sergey.name"),
          job: this.$t("about_us.sergey.job"),
          image: "/img/about_us/serega.webp",
          class: "md:mb-20 mb-14",
        },
        {
          name: this.$t("about_us.michael.name"),
          job: this.$t("about_us.michael.job"),
          image: "/img/about_us/misha.webp",
          class: "md:mb-20 mb-14",
        },
        {
          name: this.$t("about_us.olya.name"),
          job: this.$t("about_us.olya.job"),
          image: "/img/about_us/olya.webp",
          class: "md:mb-0 mb-14",
        },
        {
          name: this.$t("about_us.alexandra.name"),
          job: this.$t("about_us.alexandra.job"),
          image: "/img/about_us/sasha.webp",
          class: "md:mb-0 mb-14",
        },
        {
          name: this.$t("about_us.vitaly.name"),
          job: this.$t("about_us.vitaly.job"),
          image: "/img/about_us/vetal.webp",
        },
      ];
    },
  },
};
</script>
